import React, { FC } from 'react';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import './privacy-hero.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

interface PrivacyHeroProps {
  heroImage?: any;
  heading?: string;
  subHeading?: string;
  headingText?: string;
  featureImage?: any;
}

const PrivacyHero: FC<PrivacyHeroProps> = (props) => {
  return (
    <BackgroundImage className="w-full" fluid={props.heroImage}>
      <section id="privacy-header" className="pt-12 w-full">
        <div className="privacy-hero-container">
          <div className="privacy-textblock">
            <h2 className="text-white font-heading text-4xl mb-4 md:text-5xl font-bold leading-none">
              {props.heading}
            </h2>
            <h4 className="text-white privacy-sub-heading">{props.subHeading}</h4>
            <p className="text-white text-xl font-hairline max-w-2xl leading-relaxed privacy-headingText">
              {props.headingText}
            </p>
          </div>
          {props.featureImage && (
            <div className="privacy-feature-image">
              <Img fluid={props.featureImage} />
            </div>
          )}
        </div>
      </section>
    </BackgroundImage>
  );
};

export default PrivacyHero;
