import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import PrivacyHero from '../components/sections/privacy/privacy-hero';
import AccordionPrivacyFAQ from '../components/sections/privacy/accordion-privacy-faq'
import './privacy.css';
import IndustryPrivacySelector from '../components/sections/privacy/industry-privacy-selector';
import PrivacyButtons from '../components/sections/privacy/privacy-buttons';


const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
  query {
    privacyHero: file(relativePath: { eq: "hero-images/privacy-hero.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    privacyFeature: file(relativePath: { eq: "hero-images/privacy_feature.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `);
  
  const accordionQuestions = [
    {
      title: 'Is Patronscan privacy compliant in every jurisdiction?',
      response: 'We believe that it is fully compliant. In all consultations and investigations, the Patronscan system has been found to comply with all privacy laws in their respective jurisdictions. Patronscan limits the collection of personal information, uses of that information, and offers Patrons the ability to challenge flags that they believe are inappropriate.'
    },
    {
      title: 'Is data given away or sold?',
      response: 'No personal data is provided to third parties outside of law enforcement and venue staff. Again, unless a patron is flagged, data is permanently deleted shortly after visiting an establishment.'
    },
    {
      title: 'Can patrons request copies of their personal data?',
      response: 'Patrons have the right to request what private information has been collected, used and/or disclosed by navigating to the appropriate privacy page, clicking on the “I want to dispute a flag” or “I want to check my info” buttons, located on our privacy policies, and by filling out a disclosure request form. Once Patronscan has received the completed form, a response will be provided within 10 business days.'
    },
    {
      title: 'How is data protected?',
      response: 'Patronscan uses a variety of security technologies and procedures to help protect patron personal data from unauthorized access, use or disclosure. Patronscan stores all personal data on computer servers with access controls and that are located in controlled facilities. When, transmitting sensitive data over the internet, Patronscan protects it through the use of encryption software such as software adhering to the Secure Socket Layer (SSL) protocol. Patronscan also encrypts all data stored on its database server. Patronscan only uses certified data centers to store all data collected. The data centers are SSAE16 SOC 2 certified, security reviewed facilities with existing infrastructure of industry standard server and security technology. Procedures are in place to restrict logical access to this data center and client systems. No personal data is provided to third parties outside of law enforcement and venue staff. Again, unless a patron is flagged, data is permanently deleted shortly after visiting an establishment.'
    },
  
  ];

  return (
    <div>
      <SEO title="Patronscan - ID Scanning and Our Commitment to Privacy" />
      <PrivacyHero
        heroImage={data.privacyHero.childImageSharp.fluid}
        heading="Protecting People and Privacy"
        headingText="We care about patron privacy and we remain transparent in what Patronscan collects, why it is collected and how we keep this information safe."
        featureImage={data.privacyFeature.childImageSharp.fluid}
      />
      <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-heading"> Your Privacy</h2>
      <p className="privacy-text"> We aim to make establishments safer for patrons and to create trustworthy relationships. This is why our type of technology is endorsed by small business owners, crime victim organizations, neighborhood associations, entertainment districts, local government and law enforcement agencies.</p>
      <div className="privacy-accordion">
      <h2 className="text-3xl font-hairline font-heading text-grey-dark mb-3 privacy-accordion-header">Frequently Asked Questions</h2>
      <AccordionPrivacyFAQ questions={accordionQuestions} />
      </div>
      <p className="privacy-industry-prompt"> Patronscan offers solutions for a number of industries. We are committed to privacy across all our platforms and within all jurisdictions in which we operate. Click on an industry to see its respective privacy statements, dispute a flag or request your information.</p>
      <IndustryPrivacySelector />
    </div>
  );
};

export default PrivacyPage;
